























































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import ProjectService from "../../../services/project-service";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar
  },
})
export default class Employee extends Vue {
  private data: any[] = [];
  private currentItem: any = null;
  private currentIndex: number = -1;
  private title: string = "";
  private rows = 100;
  private currentPage = 1

  public fields = [
    {
            key: 'order',
            sortable: false
          },
          {
            key: 'employe',
            sortable: false
          },
         
          {
            key: 'user',
            sortable: false,
            
          },
          
        ];

  public items= []

  retrieve() {
    ProjectService.getAllprojectorder()
      .then((response) => {
        this.items = response.data;
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieve();
    this.currentItem = null;
    this.currentIndex = -1;
  }

  setActiveItem(data: any, index: number) {
    this.currentItem = data;
    this.currentIndex = index;
  }

  // removeAll() {
  //   hrmService.deleteAll()
  //     .then((response) => {
  //       console.log(response.data);
  //       this.refreshList();
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  searchTitle() {
    ProjectService.getByTitle(this.title)
      .then((response) => {
        this.data = response.data;
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  mounted() {
    this.retrieve();
  }
}
